import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { EventsNames } from '@features/events/components/eventsNames';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';
import { ICashoutItemTitle } from '@features/cashout/components/cashoutItemTitle/CashoutItemTitle.types';

import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';
import { formatTypeString } from '@common/helpers/cashoutHelper';

import { Icon } from '@ui/components/icon';

import * as S from './CashoutItemTitle.styled';

const MAX_WINNING_VALUE = 100000;

const CashoutItemTitle: React.FC<ICashoutItemTitle> = ({
  betId,
  extendDetail,
  extended,
}) => {
  const {
    totalStake,
    selections,
    type,
    isBonus,
    isShopEMoney,
    remainingRisk,
    isLive,
  } = useCashoutItemModel(betId);
  const { t } = useTranslation();
  const { accent } = useTheme();

  const precisionWinningsValue =
    MAX_WINNING_VALUE > Number(remainingRisk) ? 2 : 0;

  return (
    <S.Text onClick={extendDetail}>
      <S.CashoutTitleWrapper open={extended}>
        <S.CashoutTitle isBonus={isBonus === '1'}>
          {isBonus === '1' && <Icon name="gift" width="13" height="16" />}
          {isShopEMoney === '1' && (
            <Icon name="credit-card" width="16" height="16" />
          )}
          <span>
            <b>{formatBalance(totalStake)}</b>
            {' / '}
            <span title={formatTypeString(type)}>{formatTypeString(type)}</span>
          </span>
        </S.CashoutTitle>
        {!extended && (
          <span>
            {`${t('common.labels.possible_winnings')}: ${formatBalance(
              remainingRisk,
              {
                precision: precisionWinningsValue,
              },
            )}`}
          </span>
        )}
      </S.CashoutTitleWrapper>
      {!extended && (
        <S.LiveEventNamesWrapper>
          {isLive && (
            <S.CashoutLive>
              <Icon
                name="sonderwetten-outlined"
                width="10"
                height="10"
                fill={accent}
              />
              <b>{t('cashoutItemDetail.live')}</b>
            </S.CashoutLive>
          )}
          <S.EventsNamesWrapper>
            <EventsNames selections={selections} />
          </S.EventsNamesWrapper>
        </S.LiveEventNamesWrapper>
      )}
      {extended && (
        <S.GewinnChanceWrapper>
          {`${t('common.labels.possible_winnings')}: ${formatBalance(
            remainingRisk,
            {
              precision: precisionWinningsValue,
            },
          )}`}
        </S.GewinnChanceWrapper>
      )}
    </S.Text>
  );
};

export default CashoutItemTitle;
