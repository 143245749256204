export const MENU_PANEL = 'menuPanel';
export const LUGAS_ACCEPT_GLOBAL10 = 'lugas-accept-global-10';
export const LUGAS_ACCEPT_GLOBAL30 = 'lugas-accept-global-30';
export const CATEGORIES_PANEL = 'categoriesPanel';
export const WALLET_SWITCHER = 'wallet-switcher';
export const CANCEL_BONUS = 'cancel-bonus';
export const BETTING_SLIP = 'bettingSlip';
export const SUMMARY_LAYER = 'summary';
export const TERMS_LAYER = 'terms';
export const REALITY_LAYER = 'reality';
export const LUGAS_LAYER = 'lugas';
export const LUGAS_BLOCK_LAYER = 'lugas-block-layer';
export const LUGAS_COOLDOWN = 'lugas-cooldown';
export const LUGAS_INACTIVE = 'lugas-inactive';
export const WALLET_REQUEST = 'wallet-request';
export const DEPOSIT_LIMIT_LAYER = 'deposit-limit-layer';
export const BLOCK_ACCOUNT_SUBMIT_LAYER = 'block-account-submit-layer';
export const LIMIT_LAYERS = 'limit-layers';
export const LIMIT_LAYER_INCREASE = 'limit-layers_increase';
export const LIMIT_LAYER_VERIFICATION = 'limit-layers_verification';
export const LIMIT_LAYER_CREDIT_CHECK = 'limit-layers_credit_check';
export const LIMIT_LAYER_CREDIT_CHECK_APPROVED =
  'limit-layers_credit_check_approved';
export const LIMIT_LAYER_CREDIT_CHECK_REJECT =
  'limit-layers_credit_check_reject';
export const LIMIT_LOADER_LAYER = 'limit-layer_loader';
export const DYNAMIC_NOTE_LAYER = 'dynamic-notification';
export const EVENT_DETAIL = 'event_detail';
export const PUSH_NOTIFICATION_LAYER = 'push_notification_layer';
export const AUTOMATIC_DEPOSIT_CONFIRM_LAYER =
  'automatic_deposit_confirm_layer';
export const ACCEPT_COOKIE_LAYER = 'cookie-banner';
export const ODDS_COMPARISON_LAYER = 'odds-comparison-layer';
export const GCMS_FORM_SUBMISSION = 'gcms-form-submission';
export const GCMS_FORM_ERROR = 'gcms-form-error';

export const dialogsForTest = [
  MENU_PANEL,
  LUGAS_ACCEPT_GLOBAL10,
  LUGAS_ACCEPT_GLOBAL30,
  WALLET_SWITCHER,
  SUMMARY_LAYER,
  TERMS_LAYER,
  REALITY_LAYER,
  LUGAS_LAYER,
  LUGAS_BLOCK_LAYER,
  LUGAS_COOLDOWN,
  LUGAS_INACTIVE,
  WALLET_REQUEST,
  DEPOSIT_LIMIT_LAYER,
  BLOCK_ACCOUNT_SUBMIT_LAYER,
  LIMIT_LAYER_INCREASE,
  LIMIT_LAYER_VERIFICATION,
  LIMIT_LAYER_CREDIT_CHECK,
  LIMIT_LAYER_CREDIT_CHECK_APPROVED,
  LIMIT_LAYER_CREDIT_CHECK_REJECT,
  DYNAMIC_NOTE_LAYER,
  PUSH_NOTIFICATION_LAYER,
  AUTOMATIC_DEPOSIT_CONFIRM_LAYER,
  ODDS_COMPARISON_LAYER,
  CANCEL_BONUS,
  GCMS_FORM_SUBMISSION,
  GCMS_FORM_ERROR,
];

export const savedDialogs = [
  MENU_PANEL,
  CATEGORIES_PANEL,
  BETTING_SLIP,
  LUGAS_ACCEPT_GLOBAL10,
  LUGAS_ACCEPT_GLOBAL30,
  LUGAS_BLOCK_LAYER,
  LUGAS_COOLDOWN,
  REALITY_LAYER,
  WALLET_REQUEST,
  BLOCK_ACCOUNT_SUBMIT_LAYER,
  LIMIT_LAYER_INCREASE,
  LIMIT_LAYER_VERIFICATION,
];

export const MOBILE_SIDEBARS = {
  [MENU_PANEL]: true,
};
